function getLifeInsuranceValue(userModel, level) {
    if (userModel.hasPartner === false && userModel.kidsCount === 0 && level < 75) {
        return 0; // Ingen livförsäkring rekommenderas för singalr utan barn om de inte vill vara högt försäkrade
    }

    let dynamicAmount = 0; // Scales with level of insurance
    let baseAmount = 0; // Mandatory minimum

    // Schablonmässig summa för att efterlevande ska kunna bo kvar i bostaden

    if (userModel.residence.ownHouse) {
        dynamicAmount += 600000;
    } else if (userModel.residence.ownAppartment) {
        dynamicAmount += 400000;
    } else {
        dynamicAmount += 100000;
    }

    if (userModel.countryHouse) {
        dynamicAmount += 200000; // Schablon
    }

    let yearlySalary = 25000 * 12; // Gissa

    if (userModel.salary.min !== undefined && userModel.salary.max !== undefined) {
        // We have the info - refine
        yearlySalary = ((userModel.salary.min + userModel.salary.max) * 12) / 2;
    }

    dynamicAmount += yearlySalary;

    if (userModel.debt.min !== undefined && userModel.debt.max !== undefined) {
        // det finns ett lån
        let debt = (userModel.debt.min + userModel.debt.max) / 2;
        dynamicAmount += debt / 4;
        baseAmount += debt / 2;
    }

    if (userModel.age.min >= 70) {
        dynamicAmount *= 0.25;
    } else if (userModel.age.min >= 60) {
        dynamicAmount *= 0.5;
    } else if (userModel.age.min >= 50) {
        dynamicAmount *= 0.25;
    }

    let amount = baseAmount + (dynamicAmount * level) / 100;

    // Olika tak som finns
    if (yearlySalary <= 300000 && amount > 3000000) {
        amount = 3000000;
    } else {
        if (amount > 10 * yearlySalary) {
            amount = 10 * yearlySalary;
        }
        if (amount > 5000000) {
            amount = 5000000;
        }
    }

    if (amount >= 1000000) {
        return Math.round(amount / 500000) * 500000;
    } else {
        return Math.round(amount / 200000) * 200000;
    }
}

// This aggregates all individual insurance advices to an array
export default function getLifeInsuranceAdvise(userModel, level, kontor) {
    let lifeValue = getLifeInsuranceValue(userModel, level);

    let infoLiv =
        'Livförsäkringen betalar ersättning till efterlevande vid dödsfall. Den betalas i första hand till make/maka/sambo och i andra hand till arvingar.';
    let linkLiv = 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/personforsakring/livforsakring/';
    let title, titleShort;

    if (lifeValue > 0) {
        if (lifeValue > 5000000) {
            lifeValue = 5000000; // Max level
        }
        let valueText;
        if (lifeValue >= 1000000) {
            valueText = Math.round(lifeValue / 100000) / 10 + ' M kr';
        } else {
            valueText = Math.round(lifeValue / 1000) + ' 000 kr';
        }

        title = 'Livförsäkring på ' + valueText;
        titleShort = 'Liv';
    } else {
        title = 'Ingen livförsäkring';
        titleShort = undefined;
    }

    return [
        {
            key: 'liv',
            title: title,
            titleShort: titleShort,
            info: infoLiv,
            link: linkLiv,
        },
    ];
}
