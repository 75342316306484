import React, {useEffect} from 'react';
import InterviewHeaderContainer from '../../../containers/InterviewHeaderContainer';
import RecommendationItem from '../../../components/common/recommendationitems/RecommendationItem';
import CopyRecommendationItem from '../../common/recommendationitems/CopyRecommendationItem';
import './contentScene.scss';
import LevelSelector from '../../common/levelselector/LevelSelector';
import InterviewQuestionContainer from '../../../containers/InterviewQuestionContainer';
import getInsuranceAdvise from '../../../insurances/insuranceAdvisor';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import assets from '../../../assets';
import {questions} from '../../../questionsdata';
import getOffice from '../../../insurances/getOffice';
import {pageEnum} from '../../../utils';

const ContentScene = (props) => {
    let goToResultPage = props.onResult;

    useEffect(() => {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        if (urlParams.has('recommendations') && urlParams.has('level')) {
            let insuranceLevel = urlParams.get('level');
            let recommendations = urlParams.get('recommendations');
            let jsonString = Buffer.from(decodeURIComponent(recommendations), 'base64').toString();
            let model = JSON.parse(jsonString);
            goToResultPage(model, insuranceLevel);
        }
    }, [goToResultPage]);

    function getInsuranceRecommendationFromUserProfile(level, user) {
        console.log('Producing recommendation at level ' + level + ' for user', user);

        let advice = getInsuranceAdvise(user, level);
        let recommendations = [];

        for (let i = 0; i < advice.length; i++) {
            recommendations.push(
                <RecommendationItem key={advice[i].key} text={advice[i].title} info={advice[i].info} link={advice[i].link} />,
            );
        }

        let kontor = getOffice();

        var callUsText = `Göteborg – Lilla Bommen\n031-63 80 00`;

        return (
            <div>
                {recommendations}
                <RecommendationItem
                    key="gold"
                    text="Bli guldkund hos oss"
                    info="När du samlar dina bank- och försäkringsaffärer får du flera fördelar. Då blir du Guldkund och får rabatt på hushållets privata försäkringar."
                    link={'http://www.lansforsakringar.se/' + kontor + '/privat/om-oss/erbjudanden/guldkund/'}
                    icon="gold"
                />

                <RecommendationItem
                    key="call_us"
                    text="Kontakta oss"
                    info={callUsText}
                    hasButton={true}
                    office={kontor}
                    buttonText="Maila oss"
                    icon="callUs"
                />

                <CopyRecommendationItem userModel={props.userModel} insuranceLevel={level} />

                <RecommendationItem
                    key="again"
                    text="Prova igen"
                    info="Vill du svara på frågorna en gång till? "
                    linkText="Börja om"
                    link={'./?kontor=' + kontor}
                />
                <div className="footerBelowRecommendations" />
            </div>
        );
    }

    function goToNextPage() {
        props.onNextPage();
    }

    // The 'result' page shows the recommendations
    function renderWelcomePage() {
        let header, content;
        console.log('Rendering welcome page');

        var backgroundImage = assets.getUrl('img1');
        assets.prefetch(questions[0].imageName);

        header = (
            <div className="interviewHeaderRoot">
                <TransitionGroup className="transition-header-group-root">
                    <CSSTransition key={'welcome'} timeout={800} classNames="header-animation">
                        <img className="background-image" src={backgroundImage} alt="" />
                    </CSSTransition>
                </TransitionGroup>

                <div className="header-container-alone">
                    <div className="bigTitle">
                        <div className="bigTitleText">
                            Är du <span className="bigTitleAccentText">rätt</span> försäkrad?
                        </div>
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="verticalStretchedStack">
                <div className="pageInfoText">
                    Med vår digitala guide kan du enkelt se vilka försäkringar som matchar just din livssituation.
                </div>
                <div className="nextButton" onClick={() => goToNextPage()}>
                    <div className="nextButtonText">Kom igång!</div>
                </div>
            </div>
        );

        return {header, content};
    }

    // The 'form' holds pages where the quesitons are shown
    function renderFormPage() {
        let header, content;

        header = <InterviewHeaderContainer expanded={false} />;

        content = <InterviewQuestionContainer />;

        return {header, content};
    }

    // The 'result' page shows the recommendations
    function renderResultPage() {
        let header, content;

        let appState = props.appState;

        header = <InterviewHeaderContainer expanded={false} />;

        let recommendationContent = getInsuranceRecommendationFromUserProfile(appState.insuranceLevel.value, props.userModel);
        let levelSelector = <LevelSelector fixed currentLevel={appState.insuranceLevel.value} />;

        content = (
            <div className="recommendationsContainer">
                {levelSelector}
                {recommendationContent}
            </div>
        );

        return {header, content};
    }

    function renderThankYouPage() {
        let header, content;

        let appState = props.appState;
        console.log('Rendering thank you page');

        var backgroundImage = assets.getUrl('img14');
        assets.prefetch(questions[0].imageName);

        header = (
            <div className="interviewHeaderRoot">
                <TransitionGroup className="transition-header-group-root">
                    <CSSTransition key={'welcome'} timeout={800} classNames="header-animation">
                        <img className="background-image" src={backgroundImage} alt="" />
                    </CSSTransition>
                </TransitionGroup>

                <div className="header-container-alone">
                    <div className="bigTitle">
                        <div className="bigTitleText fadeIn">
                            <span className="bigTitleAccentText">Tack</span> för dina svar!
                        </div>
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="verticalStretchedStack">
                <div className="pageInfoText">
                    <p>
                        Hur stark försäkring vill du ha? <br /> Du kan enkelt ändra det här valet på nästa sida för att se hur
                        det påverkar vår rekommendation!
                    </p>
                </div>
                <LevelSelector currentLevel={appState.insuranceLevel.value} />
                <div className="nextButton" onClick={() => goToNextPage()}>
                    <div className="nextButtonText">Visa resultat</div>
                </div>
            </div>
        );

        return {header, content};
    }

    let appState = props.appState;

    let page = {};

    switch (appState.currentPage) {
        case pageEnum.welcome:
            page = renderWelcomePage();
            break;
        case pageEnum.form:
            page = renderFormPage();
            break;
        case pageEnum.done:
            page = renderThankYouPage();
            break;
        case pageEnum.result:
            page = renderResultPage();
            break;
        default:
            console.error('Current page is something unknown!');
    }

    return (
        <div className="contentSceneRoot">
            {page.header}
            {page.content}
        </div>
    );
};

export default ContentScene;
