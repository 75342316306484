// This aggregates all individual insurance advices to an array
export default function getBoatInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    if (userModel.vehicle_boat === true) {
        if (level < 30) {
            advice.push({
                key: 'boat-insurance',
                title: 'Båt: Försäkringsnivå Ansvar',
                titleShort: 'Båt',
                info: "Vår båtförsäkring 'Ansvar' hjälper dig om du blir skadeståndsskyldig för skada på annan person eller annans egendom.",
                link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/batforsakring/',
            });
        } else if (level < 80 || (userModel.kidsCount > 0 && level < 50)) {
            advice.push({
                key: 'boat-insurance',
                title: 'Båt: Försäkringsnivå BAS',
                titleShort: 'Båt',
                info: 'Vår båtförsäkring BAS ger dig och din båt ett grundläggande skydd om olyckan skulle vara framme.',
                link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/batforsakring/',
            });
        } else {
            advice.push({
                key: 'boat-insurance',
                title: 'Båt: Försäkringsnivå MAX',
                titleShort: 'Båt',
                info: 'Vår båtförsäkring MAX ger dig och din båt ett grundläggande skydd om olyckan skulle vara framme, samt hjälp med bogsering eller transport av bränsle eller reservdelar.',
                link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/batforsakring/',
            });
        }

        // har man barn så rekommendera tilläggsförsäkringen starkare än de utan barn
        if (level > 90) {
            advice.push({
                key: 'boat-insurance-mer',
                title: 'Båt: Tillägg för uthyrning och andra farvatten',
                titleShort: 'Båt Extra',
                info: 'Om du vill hyra ut din båt eller ta den till geografiska områden än där försäkringen inte gäller behöver du ha en tilläggsförsäkring.',
                link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/batforsakring/',
            });
        }
    }

    console.log('Boat insurance:', advice);
    return advice;
}
