import { pageEnum } from '../utils';

export const preFilledState = {
    appState: {
        // The first active will be shown
        currentPage: pageEnum.result,

        questionIndex: 0,
        insuranceLevel: { value: 50 },
    },

    answers: [],

    // 40+ with lot of stuff
    //userModel: JSON.parse('{"age":{"min":40,"max":49},"hasPartner":true,"kidsCount":2,"residence":{"ownHouse":true},"ownHouseExtrasGreenHouse":true,"ownHouseExtrasGarage":true,"countryHouse":true,"salary":{"min":40000,"max":45000},"debt":{"min":3000000,"max":4000000},"vehicle_boat":true,"vehicle_carCount":2,"vehicle_mc":true,"vehicle_slapvagn":true,"vehicle_terrain":true,"pets_dog":true,"pets_cat":true}')

    // Someone who has it all
    userModel: JSON.parse(
        '{"age":{"min":40,"max":49},"hasPartner":true,"kidsCount":4,"residence":{"ownHouse":true},"ownHouseExtrasGreenHouse":true,"ownHouseExtrasFriggebod":true,"ownHouseExtrasGarage":true,"ownHouseExtrasGuestCottage":true,"ownHouseExtrasOther":true,"countryHouse":true,"salary":{"min":45000,"max":50000},"debt":{"min":4000000,"max":5000000},"vehicle_boat":true,"vehicle_carCount":3,"vehicle_mc":true,"vehicle_moped":true,"vehicle_entusiast":true,"vehicle_husbil":true,"vehicle_husvagn":true,"vehicle_lightTruck":true,"vehicle_slapvagn":true,"vehicle_terrain":true,"vehicle_snoskoter":true,"pets_dog":true,"pets_cat":true,"pets_horse":true,"pets_small":true,"pets_other":true}',
    ),
};

const initialState = {
    appState: {
        currentPage: pageEnum.welcome,

        questionIndex: 0,
        insuranceLevel: {
            value: 50,
        },
    },
    userModel: {
        age: { min: undefined, max: undefined },
        hasPartner: undefined,
        kidsCount: undefined,

        residence: {
            ownHouse: undefined,
            ownAppartment: undefined,
            rentalAppartment: undefined,
        },

        ownHouseExtrasGreenHouse: undefined,
        ownHouseExtrasFriggebod: undefined,
        ownHouseExtrasGarage: undefined,
        ownHouseExtrasGuestCottage: undefined,
        ownHouseExtrasOther: undefined,

        // Somamrstuga mm
        countryHouse: undefined,

        salary: { min: undefined, max: undefined },
        debt: { min: undefined, max: undefined },

        vehicle_boat: undefined,
        vehicle_carCount: undefined,
        vehicle_mc: undefined,
        vehicle_moped: undefined,
        vehicle_entusiast: undefined,
        vehicle_husbil: undefined,
        vehicle_husvagn: undefined,
        vehicle_lightTruck: undefined,
        vehicle_slapvagn: undefined,
        vehicle_terrain: undefined,
        vehicle_snoskoter: undefined,

        pets_dog: undefined,
        pets_cat: undefined,
        pets_horse: undefined,
        pets_small: undefined,
        pets_other: undefined,
    },

    answers: [],
};

export default initialState;
