import utils from '../utils';

// This aggregates all individual insurance advices to an array
export default function getHealthInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    if (userModel.age.min >= 60) {
        if (level >= 50) {
            advice.push({
                key: 'accident',
                title: 'Olycksfall 60 PLUS',
                titleShort: 'Olyckasfall 60+',
                info: 'Det här är en olycksfallsförsäkring som ger dig som fyllt 60 år ersättning om du drabbas av olycksfallsskador. ',
                link:
                    'https://www.lansforsakringar.se/' +
                    kontor +
                    '/privat/forsakring/personforsakring/olycksfall-sjukforsakring/',
            });
        }
    } else {
        if (level >= 60) {
            advice.push({
                key: 'accident',
                title: 'Olycksfalls- och Sjukförsäkring',
                titleShort: 'Olycksfall & Sjuk',
                info: 'Med vår Olycksfalls- och Sjukförsäkring för vuxna får du skydd både vid olycksfallsskador och sjukdom.',
                link:
                    'https://www.lansforsakringar.se/' +
                    kontor +
                    '/privat/forsakring/personforsakring/olycksfall-sjukforsakring/',
            });
        } else if (level >= 30) {
            advice.push({
                key: 'accident',
                title: 'Olycksfallsförsäkring',
                titleShort: 'Olycksfall',
                info: 'Med vår individuella olycksfallsförsäkring får du ersättning om du drabbas av olycksfallsskador.',
                link:
                    'https://www.lansforsakringar.se/' +
                    kontor +
                    '/privat/forsakring/personforsakring/olycksfall-sjukforsakring/',
            });
        }
    }

    let salary = 25000; // Gissa
    if (userModel.salary.min !== undefined && userModel.salary.max !== undefined) {
        // We have the info - refine
        salary = (userModel.salary.min + userModel.salary.max) / 2;
    }

    let heathCareThreshold = utils.blendAndClamp(70, 50, 20000, salary, 40000); // Require > 70% to recommend for 20k salary
    let heathCareExtraThreshold = utils.blendAndClamp(90, 70, 20000, salary, 40000); // Require > 90% to recommend for 20k salary

    if (level >= heathCareExtraThreshold) {
        advice.push({
            key: 'health-care',
            title: 'Sjukvårdsförsäkring med tillägg',
            titleShort: 'Sjukvård',
            info: 'Med vår sjukvårdsförsäkring får du enkelt tillgång till vård och rehabilitering. Med tilläggspaketet ingår även till exempel hälsokontroller och vaccinationer.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/personforsakring/sjukvardsforsakring/',
        });
    } else if (level >= heathCareThreshold) {
        advice.push({
            key: 'health-care',
            title: 'Sjukvårdsförsäkring',
            titleShort: 'Sjukvård',
            info: 'Med vår sjukvårdsförsäkring får du enkelt tillgång till vård och rehabilitering.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/personforsakring/sjukvardsforsakring/',
        });
    }

    console.log('Health insurance:', advice);
    return advice;
}
