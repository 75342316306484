import {connect} from 'react-redux';
import React, {Component} from 'react';
import './levelselector.scss';
import {setInsuranceLevel} from '../../../actions/insuranceLevelActions';
import AnswerSlider from '../slider/AnswerSlider';

export function setLevel(level) {
    return (dispatch, getState) => {
        dispatch(setInsuranceLevel(level));
    };
}

class LevelSelector extends Component {
    constructor(props) {
        super(props);
        this.initialValue = props.currentLevel;
        this.state = {currentItemText: this.mapLevelToLevelLabel(this.initialValue)};
        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    mapLevelToLevelLabel(value) {
        if (value < 100 / 7) {
            return 'Lägst Skydd';
        }
        if (value < (100 * 2) / 7) {
            return 'Extra Lågt Skydd';
        }
        if (value < (100 * 3) / 7) {
            return 'Lågt Skydd';
        }
        if (value < (100 * 4) / 7) {
            return 'Medelstarkt Skydd';
        }
        if (value < (100 * 5) / 7) {
            return 'Starkt Skydd';
        }
        if (value < (100 * 6) / 7) {
            return 'Extra Starkt Skydd';
        }
        return 'Starkast Skydd';
    }

    setLevel(level) {
        this.props.onSetLevel(level);
        this.setState({currentItemText: this.mapLevelToLevelLabel(level)});
    }

    handleSliderChange(value) {
        this.setLevel(value);
    }

    render() {
        return (
            <div className={'insuranceLevelSelectorRoot' + (this.props.fixed ? ' fixed' : '')}>
                <div className="currentLevelLabel">{this.state.currentItemText}</div>
                <div className="levelSliderContainer">
                    <AnswerSlider onUpdate={this.handleSliderChange} initialValue={this.initialValue} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        level: state.appState.insuranceLevel.value,
    };
};

const mapDispatchToProps = {
    onSetLevel: setLevel,
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelSelector);
