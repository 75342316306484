import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './recommendationitem.scss';
import assets from '../../../assets';

class RecommendationItem extends Component {
    static propTypes = {
        linkItem: PropTypes.bool,
        linkText: PropTypes.string,
        text: PropTypes.string,
        info: PropTypes.string,
        icon: PropTypes.string,
        hasButton: PropTypes.bool,
        buttonText: PropTypes.string,
        office: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    /*

    +-----recommendationItemRoot-------------+
    | +--icon---+-recommendationContainer--+ |
    | |         |                          | |
    | |         |                          | |
    | |  icon   |        content           | |
    | |         |                          | |
    | |         |                          | |
    | |         |                          | |
    | +---------+--------------------------+ |
    +----------------------------------------+

    */

    alvsborgOfficeTable() {
        return (
            <table>
                <tbody>
                    <tr>
                        <td className="firstColumn">Måndag–Torsdag</td>
                        <td className="secondColumn">08:00–18:00</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Fredag</td>
                        <td className="secondColumn">08:00–17:00</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Lördag–Söndag</td>
                        <td className="secondColumn">Stängt</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    hallandOfficeTable() {
        return (
            <table>
                <tbody>
                    <tr>
                        <td className="firstColumn">Måndag–Fredag</td>
                        <td className="secondColumn">08:00–16:30</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Lördag–Söndag</td>
                        <td className="secondColumn">Stängt</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    goteborgOfficeTable() {
        return (
            <table>
                <tbody>
                    <tr>
                        <td className="firstColumn">Måndag–Torsdag</td>
                        <td className="secondColumn">08:00–17:00</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Fredag</td>
                        <td className="secondColumn">08:00–16:00</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Lördag–Söndag</td>
                        <td className="secondColumn">Stängt</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    getOfficeVisitingHoursLayout(office) {
        // In the future this should propably be done by some API-call to get updated info. But for now, this is how we can do it.
        switch (office) {
            case 'alvsborg':
                return this.alvsborgOfficeTable();
            case 'halland':
                return this.hallandOfficeTable();
            case 'goteborg-och-bohuslan':
                return this.goteborgOfficeTable();
            default:
                return this.goteborgOfficeTable();
        }
    }

    getOfficeNameAndTelephoneLayout(office) {
        // In the future this should propably be done by some API-call to get updated info. But for now, this is how we can do it.
        switch (office) {
            case 'alvsborg':
                return this.alvsborgOfficeAndTelephoneTextDiv();
            case 'halland':
                return this.hallandOfficeAndTelephoneTextDiv();
            case 'goteborg-och-bohuslan':
                return this.goteborgOfficeAndTelephoneTextDiv();
            default:
                return this.goteborgOfficeAndTelephoneTextDiv();
        }
    }

    alvsborgOfficeAndTelephoneTextDiv() {
        return (
            <div className="officeAndPhoneNumberWrapper">
                <div>Länsförsäkringar – Älvsborg</div>
                <div>0521-27 30 00</div>
                <div className="linkWrapper"><a href="mailto:info@alvsborg.lansforsakringar.se" target="_blank" rel="noreferrer"> info@alvsborg.lansforsakringar.se </a></div>
            </div>
        );
    }

    hallandOfficeAndTelephoneTextDiv() {
        return (
            <div className="officeAndPhoneNumberWrapper">
                <div>Länsförsäkringar – Halland</div>
                <div>035-15 10 00</div>
                <div className="linkWrapper"><a href="mailto:info@lfhalland.se" target="_blank" rel="noreferrer"> info@lfhalland.se </a></div>
            </div>
        );
    }

    goteborgOfficeAndTelephoneTextDiv() {
        return (
            <div className="officeAndPhoneNumberWrapper">
                <div>Länsförsäkringar – Göteborg och Bohuslän</div>
                <div>031-63 80 00</div>
                <div className="linkWrapper"><a href="mailto:info@gbg.lansforsakringar.se" target="_blank" rel="noreferrer"> info@gbg.lansforsakringar.se </a></div>
            </div>
        );
    }


    render() {
        let link;
        let content;

        let image;
        if (this.props.icon) {
            let url = assets.getUrl(this.props.icon);
            image = <img className="recommendationIcon" src={url} width="45px" height="45px" alt="" />;
        }

        let emailString;
        let office = this.props.office;
        switch (office) {
            case 'alvsborg':
                emailString = 'info@alvsborg.lansforsakringar.se';
                break;
            case 'halland':
                emailString = 'info@lfhalland.se';
                break;
            case 'goteborg-och-bohuslan':
                emailString = 'info@gbg.lansforsakringar.se';
                break;
            default:
                emailString = 'info@gbg.lansforsakringar.se';
        }

        if (this.props.linkItem === true) {
            content = (
                <div className="recommendationContainer itemLink">
                    {image}
                    <div className="recommendationTextWrapper">
                        <div className="recommendationText itemLink">{this.props.text}</div>
                        <div className="infoText itemLink">{this.props.info}</div>
                    </div>
                </div>
            );
            return (
                <a className="recommendationItemRoot itemLink" href={this.props.link} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            );
        } else if (this.props.hasButton === true) {
            const formattedBody = "Fyll i dina personuppgifter så kontaktar vi: \n Namn: \n Telefonnummer:  \n Email: ";
            const mailToLink = "mailto:"+emailString+"?body=" + encodeURIComponent(formattedBody);
            content = (
                <div className="recommendationWithButtonContainer">
                    <div className="recommendationTextAndImageContainer">
                        {image}
                        <div className="recommendationTextWrapper">
                            <div className="recommendationText">{this.props.text}</div>
                            {this.getOfficeNameAndTelephoneLayout(this.props.office)}
                            {this.getOfficeVisitingHoursLayout(this.props.office)}
                        </div>
                    </div>
                    <a className="infoButton" target="_blank" rel="noreferrer" href={mailToLink}>
                        <div className="infoButtonText">{this.props.buttonText}</div>
                    </a>
                </div>
            );

            return <div className="recommendationItemRoot">{content}</div>;
        } else {
            if (this.props.link) {
                let linkText = this.props.linkText;

                if (linkText === undefined) {
                    linkText = <span>Läs&nbsp;mer</span>;
                }
                link = (
                    <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                        {linkText}
                    </a>
                );
            }

            content = (
                <div className="recommendationContainer">
                    {image}
                    <div className="recommendationTextWrapper">
                        <div className="recommendationText">{this.props.text}</div>
                        <div className="infoText">
                            {this.props.info} {link}
                        </div>
                    </div>
                </div>
            );

            return <div className="recommendationItemRoot">{content}</div>;
        }
    }
}

export default RecommendationItem;
