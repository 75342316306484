// This aggregates all individual insurance advices to an array
export default function getExtraVehicleInsuranceAdvice(userModel, level, kontor) {
    let advice = [];

    if (userModel.vehicle_mc === true) {
        let title;
        let exempelvis;
        if (level < 30) {
            title = 'MC: Trafikförsäkring';
            exempelvis = 'Exempelvis en Basförsäkring. ';
        } else if (level < 80) {
            title = 'MC: Halvförsäkring';
            exempelvis = 'Exempelvis en Årsförsäkring eller MC Smart. ';
        } else {
            title = 'MC: Helförsäkring';
            exempelvis = 'Exempelvis MC Smart. ';
        }
        advice.push({
            key: 'vehicle-mc',
            title: title,
            titleShort: 'MC',
            info:
                exempelvis +
                'För dig som vill försäkra din motorcykel har vi lösningar anpassade till ditt användande. Grunden är en trafikförsäkring som går att bygga ut utifrån när och hur ofta du kör.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/motorcykel/',
        });
    }

    if (userModel.vehicle_moped === true) {
        let title;
        if (level < 40) {
            title = 'Moped: Trafikförsäkring';
        } else if (level < 90) {
            title = 'Moped: Halvförsäkring';
        } else {
            title = 'Moped: Helförsäkring';
        }
        advice.push({
            key: 'vehicle-moped',
            title: title,
            titleShort: 'Moped',
            info: 'Beroende på vilken moped du har så skiljer sig våra försäkringar åt. Hos oss kan du välja på vilken nivå du vill skydda din moped, med start i en trafikförsäkring.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/mopedforsakring/',
        });
    }

    if (userModel.vehicle_entusiast === true) {
        advice.push({
            key: 'vehicle-entusiast',
            title: 'Entusiastförsäkring',
            titleShort: 'Entusiastbil',
            info: 'Om du har en veteranbil eller hobbybil så vänder sig vår entusiastförsäkring till dig. Med en entusiastförsäkring är din bil helförsäkrad.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/veteranbil/',
        });
    }

    if (userModel.vehicle_husbil === true) {
        let title;
        if (level < 30) {
            title = 'Husbil: Trafikförsäkring';
        } else if (level < 80) {
            title = 'Husbil: Halvförsäkring';
        } else {
            title = 'Husbil: Helförsäkring';
        }
        advice.push({
            key: 'vehicle-husbil',
            title: title,
            titleShort: 'Husbil',
            info: 'Med vår husbilsförsäkring ingår den obligatoriska trafikförsäkringen, du kan även välja att utöka ditt skydd utifrån dina behov. Trafikförsäkringen gäller i nästan hela Europa vilket ger dig och din familj en trygg resa.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/mopedforsakring/',
        });
    }

    if (userModel.vehicle_husvagn === true && level > 20) {
        let title;
        if (level < 85) {
            title = 'Husvagn: Halvförsäkring';
        } else {
            title = 'Husvagn: Helförsäkring';
        }
        advice.push({
            key: 'vehicle-husvagn',
            title: title,
            titleShort: 'Husvagn',
            info: 'Din husvagn täcks inte fullt ut av din bils försäkring. Du kan därför behöva försäkra din husvagn för ett fullgott skydd. Hos oss hittar du en lösning för en trygg semester.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/husvagn/',
        });
    }

    if (userModel.vehicle_lightTruck === true) {
        let title;
        if (level < 30) {
            title = 'Lätt lastbil: Trafikförsäkring';
        } else if (level < 80) {
            title = 'Lätt lastbil: Halvförsäkring';
        } else {
            title = 'Lätt lastbil: Helförsäkring';
        }
        advice.push({
            key: 'vehicle-light-truck',
            title: title,
            titleShort: 'Lastbil',
            info: 'Försäkra din lätta lastbil hos oss. Grunden är den obligatoriska trafikförsäkringen, vi har även lösningar för ett utökat skydd.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/latt-lastbil/',
        });
    }

    if (userModel.vehicle_slapvagn === true && level > 20) {
        let title;
        if (level < 90) {
            title = 'Släpvagn: Halvförsäkring';
        } else {
            title = 'Släpvagn: Helförsäkring';
        }
        advice.push({
            key: 'vehicle-slapvagn',
            title: title,
            titleShort: 'Släpvagn',
            info: 'Din bils trafikförsäkring täcker inte alla skador som kan uppstå med din släpvagn. Här hittar du försäkringar som exempelvis täcker kostnaden om din släpvagn skulle skada ett annat fordon under färd.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/slapvagn/',
        });
    }

    if (userModel.vehicle_terrain === true) {
        let title;
        if (level < 35) {
            title = 'Terränghjulingsförsäkring: Trafik';
        } else if (level < 90) {
            title = 'Terränghjulingsförsäkring: Halv';
        } else {
            title = 'Terränghjulingsförsäkring: Hel';
        }
        advice.push({
            key: 'vehicle-terrain',
            title: title,
            titleShort: 'Terränghjuling',
            info: 'Trafikförsäkring är ett krav enligt lag, även om du bara kör din terränghjuling i skogen. Utifrån dina behov erbjuder vi försäkringslösningar som sträcker sig från trafikförsäkring till helförsäkring.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/terranghjuling/',
        });
    }

    if (userModel.vehicle_snoskoter === true) {
        let title;
        if (level < 35) {
            title = 'Snöskoterförsäkring: Trafik';
        } else if (level < 90) {
            title = 'Snöskoterförsäkring: Halv';
        } else {
            title = 'Snöskoterförsäkring: Hel';
        }
        advice.push({
            key: 'vehicle-snoskoter',
            title: title,
            titleShort: 'Snöskoter',
            info: 'Äger och använder du en snöskoter så krävs det en trafikförsäkring enligt lag. Vi kan även hjälpa dig att teckna ytterligare skydd, utifrån dina specifika behov.',
            link: 'https://www.lansforsakringar.se/' + kontor + '/privat/forsakring/fordonsforsakring/snoskoter/',
        });
    }

    console.log('Extra vehicle insurance:', advice);
    return advice;
}
