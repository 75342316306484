import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './interviewHeader.scss';
import AnswerItem from '../interviewquestion/answeritems/AnswerItem';
import ProgressBar from '../progressbar/ProgressBar';
import assets from '../../../assets';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {questions} from '../../../questionsdata';
import getInsuranceAdvise from '../../../insurances/insuranceAdvisor';
import {pageEnum} from '../../../utils';

export default class InterviewHeader extends Component {
    static propTypes = {
        expanded: PropTypes.bool,
        appState: PropTypes.object.isRequired,
        answers: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.latestPrefetchIndex = -1;
        this.rootRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.appState || !this.props.appState.currentPage) {
            return;
        }

        if (this.props.appState.currentPage === pageEnum.form) {
            this.rootRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }

    getTitle(answers, expanded) {
        if (answers.length === 0) {
            return (
                <div className="headerTitle">
                    <div className="headerTitleText fadeIn">
                        Är du <span className="headerTitleAccentText">rätt</span> försäkrad?
                    </div>
                </div>
            );
        } else {
            return (
                <div className={expanded ? 'headerAnswers multiRow' : 'headerAnswers'}>
                    {answers.map((item, index) => {
                        let text = item.text;

                        if (item.answerText !== undefined) {
                            text = item.answerText; // Special answer text (not same as on the button)
                        }

                        return <AnswerItem isNarrow={true} key={index} text={text} />;
                    })}
                </div>
            );
        }
    }

    // Prefetch images expected next.
    prefetch(currentIndex) {
        if (this.latestPrefetchIndex === currentIndex) {
            // Skip prefetch since this has already been made with this index
            return;
        }
        this.latestPrefetchIndex = currentIndex;
        let lookAhead = 3; // Load these number of images ahead
        for (let i = currentIndex + 1; i < questions.length && i <= currentIndex + lookAhead; i++) {
            assets.prefetch(questions[i].imageName);
        }
    }

    _renderRecommendationItems() {
        if (this.props.appState.currentPage === pageEnum.form) {
            return;
        }
        let advice = getInsuranceAdvise(this.props.userModel, this.props.appState.insuranceLevel.value);

        return (
            <div className="recommendationsContainer">
                {advice.map((item, index) => {
                    if (!item.titleShort) {
                        return undefined;
                    }
                    return (
                        <div className="recommendationItemContainer" key={index}>
                            <AnswerItem resultItem={true} isTick={true} key={item.key} text={item.titleShort} />
                        </div>
                    );
                })}
            </div>
        );
    }

    renderAnswersAndProgress() {
        const {appState, answers, expanded} = this.props;

        let maxValue = questions.length;
        let progressValue;
        if (appState.currentPage === pageEnum.form) {
            progressValue = appState.questionIndex;
        } else {
            progressValue = maxValue;
        }

        // Add 1 to these to avoid an empty progress bar
        // at the first screen. You ahve made some progress by just getting
        // there
        progressValue += 1;
        if (progressValue > maxValue) {
            progressValue = maxValue;
        }

        return (
            <div className="header-container">
                <div className="headerContainers">{this.getTitle(answers, expanded)}</div>
                <div className="progressBarContainer">
                    <ProgressBar value={progressValue} maxValue={maxValue} />
                </div>
            </div>
        );
    }

    renderResultHeader() {
        return (
            <div className="header-container">
                <div className="bigTitle">
                    <div className="bigTitleText">
                        Baserat på dina svar <span className="bigTitleAccentText">erbjuder Länsförsäkringar</span> följande produkter:
                    </div>
                </div>
                {this._renderRecommendationItems()}
            </div>
        );
    }

    render() {
        const {appState} = this.props;

        var backgroundImage = assets.getUrl(questions[appState.questionIndex].imageName);

        if(appState.currentPage !== pageEnum.form) {
            backgroundImage = assets.getUrl('img14');
        }

        this.prefetch(appState.questionIndex);

        return (
            <div className="interviewHeaderRoot" ref={this.rootRef}>
                <TransitionGroup className="transition-header-group-root">
                    <CSSTransition key={appState.questionIndex} timeout={800} classNames="header-animation">
                        <img className="background-image" src={backgroundImage} alt="" />
                    </CSSTransition>
                </TransitionGroup>

                {appState.currentPage === pageEnum.form ? this.renderAnswersAndProgress() : this.renderResultHeader()}
            </div>
        );
    }
}
