import PropTypes from 'prop-types';

const CopyRecommendationItem = ({userModel, insuranceLevel}) => {
    return (
        <div className="recommendationItemRoot">
            <div className="recommendationContainer">
                <div className="recommendationTextWrapper">
                    <div className="recommendationText">Kopiera dina svar</div>
                    <div className="infoText">
                        Vill du skicka över dina svar eller spara dem till en annan gång? Genom att trycka på knappen
                        får du en länk som går direkt till en sida över dina svar.
                    </div>

                    <div
                        className="infoButton"
                        onClick={() => {
                            let jsonString = JSON.stringify(userModel);
                            let base64string = Buffer.from(jsonString).toString('base64');
                            let host = window.location.host;
                            let search = window.location.search;
                            let params = new URLSearchParams(search);
                            params.append('level', insuranceLevel);
                            params.append('recommendations', encodeURIComponent(base64string));
                            let copyUrl = host + '/?' + params.toString();
                            navigator.clipboard.writeText(copyUrl);
                            console.log('Copied: ' + copyUrl);
                        }}>
                        <div className="infoButtonText">Kopiera länk</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CopyRecommendationItem.propTypes = {
    userModel: PropTypes.object.isRequired,
};

export default CopyRecommendationItem;
