import initialState from './initialState';
import {GO_TO_RESULT, NEXT_QUESTION} from '../actions/appStateActions';
import {SET_INSURANCE_LEVEL} from '../actions/insuranceLevelActions';
import {questions} from '../questionsdata';
import {pageEnum} from '../utils';

// This handles the 'appState' property in the global store
// so the state varaible below is referring to that part of
// the store and not the entire store.
export default function appStateReducer(state = initialState.appState, {type, payload}) {
    switch (type) {
        case NEXT_QUESTION: {
            // This will become the next app state

            let s = structuredClone(state);

            switch (s.currentPage) {
                case pageEnum.welcome:
                    s.currentPage = pageEnum.form;
                    s.questionIndex = 0;
                    break;
                case pageEnum.form:
                    let done = false;
                    do {
                        if (s.questionIndex < questions.length - 1) {
                            console.log('NEXT STATE: Next question');
                            s.questionIndex++;

                            let userModel = payload.userModel;

                            // Some questions depend on previous answers
                            let filter = questions[s.questionIndex].filter;

                            if (filter !== undefined && filter(userModel) === false) {
                                console.log('Skipping followup question');
                            } else {
                                done = true;
                            }
                        } else {
                            console.log('NEXT STATE: Questions -> Done');
                            // Move to next phase
                            s.currentPage = pageEnum.done;
                            done = true;
                        }
                    } while (!done);
                    break;
                case pageEnum.done:
                    console.log('NEXT STATE: Done -> Summary');
                    s.currentPage = pageEnum.result;
                    break;
                default:
                    break;
            }

            return s;
        }
        case GO_TO_RESULT: {
            let s = structuredClone(state);
            console.log('GO TO RESULT:');
            s.currentPage = pageEnum.result;
            return s;
        }
        case SET_INSURANCE_LEVEL: {
            let s = structuredClone(state);
            console.log('NEW LEVEL: ', s);
            s.insuranceLevel.value = payload;
            return s;
        }

        default:
            return state;
    }
}
