import React, {Component} from 'react';
import {Slider, Handles, Tracks} from 'react-compound-slider';
import './answerslider.scss';

class AnswerSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleUpdate = this.handleUpdate.bind(this);
        this.latestValue = 0;
        this.updateTimer = undefined;
    }
    componentWillUnmount() {
        if (this.updateTimer !== undefined) {
            clearTimeout(this.updateTimer);
            this.updateTimer = undefined;
        }
    }

    handleUpdate(value) {
        this.latestValue = value; // Save latest value

        if (this.updateTimer !== undefined) {
            // An update callback is already scheduled.
            return;
        }

        // Schedule an update callback to allow the user to
        // drag the slider further. This throttles the update frequency
        // to at most 5 times per second in case the delay is 200ms
        this.updateTimer = setTimeout(() => {
            this.updateTimer = undefined;
            if (this.props.onUpdate) {
                this.props.onUpdate(this.latestValue);
            }
        }, 200);
    }

    renderHandle(handle, getHandleProps) {
        return (
            <div
                key={handle.id}
                className="sliderHandle"
                style={{
                    left: `${handle.percent}%`,
                    position: 'absolute',
                    marginLeft: -15,
                    marginTop: 10,
                    zIndex: 2,
                    width: 30,
                    height: 30,
                    border: 0,
                    textAlign: 'center',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    backgroundColor: '#ffffff',
                    boxShadow: '0 0.5px 2.5px 0px rgba(0, 0, 0, 0.20)',
                    color: '#333',
                }}
                {...getHandleProps(handle.id)}>
                {/* <div style={{fontSize: 11, marginTop: -25}}>{handle.value}</div> */}
            </div>
        );
    }

    renderTrack(id, source, target, getTrackProps) {
        // your own track component
        return (
            <div
                key={id}
                style={{
                    position: 'absolute',
                    height: 5,
                    zIndex: 1,
                    marginTop: 22.5,
                    backgroundColor: '#005aa0',
                    borderRadius: 5,
                    cursor: 'pointer',
                    left: `${source.percent}%`,
                    width: `${target.percent - source.percent}%`,
                }}
                {...getTrackProps()} // this will set up events if you want it to be clickeable (optional)
            />
        );
    }

    render() {
        const sliderStyle = {
            // Give the slider some width
            position: 'relative',
            width: '100%',
            height: 40,
        };
        const railStyle = {
            position: 'absolute',
            width: '100%',
            height: 5,
            marginTop: 22.5,
            borderRadius: 5,
            backgroundColor: '#e30613',
            opacity: '0.2',
        };

        return (
            <Slider
                rootStyle={sliderStyle}
                domain={[0, 100]}
                step={16.7}
                values={[this.props.initialValue]}
                onUpdate={this.handleUpdate}>
                <div style={railStyle} />
                <Handles>
                    {({handles, getHandleProps}) => (
                        <div className="slider-handles">
                            {handles.map((handle) => {
                                return this.renderHandle(handle, getHandleProps);
                            })}
                        </div>
                    )}
                </Handles>
                <Tracks right={false}>
                    {({tracks, getTrackProps}) => (
                        <div className="slider-tracks">
                            {tracks.map(({id, source, target}) => {
                                return this.renderTrack(id, source, target, getTrackProps);
                            })}
                        </div>
                    )}
                </Tracks>
            </Slider>
        );
    }
}

export default AnswerSlider;
